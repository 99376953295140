import { createColumnHelper } from '@tanstack/react-table'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const columnHelper = createColumnHelper()

const getColumns = (firstRowData, accounts = []) => {
  const getAccountName = (accountId) => accounts[accountId]?.accountName

  return [
    columnHelper.accessor('element1', {
      header: () => 'Business Unit',
      cell: (info) => (
        <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-right`}>{info.getValue()}</Tooltip>}>
          <div className="text-ellipsis">{info.getValue()}</div>
        </OverlayTrigger>
      )
    }),
    // Optionals

    ...(firstRowData.element2
      ? [
          columnHelper.accessor('element2', {
            header: () => 'Department',
            cell: (info) => (
              <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-right`}>{info.getValue()}</Tooltip>}>
                <div className="text-ellipsis">{info.getValue()}</div>
              </OverlayTrigger>
            )
          })
        ]
      : []),
    ...(firstRowData.element3
      ? [
          columnHelper.accessor('element3', {
            header: () => 'Portfolio',
            cell: (info) => (
              <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-right`}>{info.getValue()}</Tooltip>}>
                <div className="text-ellipsis">{info.getValue()}</div>
              </OverlayTrigger>
            )
          })
        ]
      : []),
    ...(firstRowData.element4
      ? [
          columnHelper.accessor('element4', {
            header: () => 'Product',
            cell: (info) => (
              <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-right`}>{info.getValue()}</Tooltip>}>
                <div className="text-ellipsis">{info.getValue()}</div>
              </OverlayTrigger>
            )
          })
        ]
      : []),
    columnHelper.accessor('accountId', {
      header: () => 'AWS Account',
      cell: (info) => (
        <>
          <div className="fw-bold">{getAccountName(info.getValue())}</div>
          <div className="text-muted">{info.getValue()}</div>
        </>
      )
    }),
    columnHelper.accessor('percentage', {
      header: () => 'Allocation',
      cell: ({ getValue }) => `${getValue()}%`
    }),
    columnHelper.accessor(
      (row) => {
        return row.status
      },
      {
        id: 'status',
        header: 'Status',
        sortingFn: (rowA, rowB, columnId) => {
          const order = ['New', 'Expiring', 'Current', 'Future', 'Past']

          return order.indexOf(rowA.getValue(columnId)) - order.indexOf(rowB.getValue(columnId))
        },
        sortDescFirst: false
      }
    )
  ]
}

export default getColumns
